import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { getOrderById } from "../services/orderService";
import { KTIcon } from "../../_metronic/helpers";
import { getBuyerAccountById } from "../services/buyerServices/buyerAccountByIdService";

const OrderInfo = () => {
  const { orderId } = useParams();
  const [startImageError, setStartImageError] = useState(false);
  const [endImageError, setEndImageError] = useState(false);


  // ✅ Obtener la orden por ID
  const { data: order, isLoading: orderLoading, isError: orderError } = useQuery({
    queryKey: ["order", orderId],
    queryFn: () => getOrderById(orderId),
    enabled: !!orderId,
  });

  // ✅ Obtener la cuenta del comprador SOLO si existe `buyerAccountId`
  const { data: buyerAccount, isLoading: buyerLoading, isError: buyerError } = useQuery({
    queryKey: ["buyerAccount", order?.buyerAccountId],
    queryFn: () => getBuyerAccountById(order?.buyerAccountId),
    enabled: !!order?.buyerAccountId,
  });

  // 📌 Manejo de estados
  if (orderLoading || buyerLoading) return <Spinner animation="border" className="text-primary" />;
  if (orderError) return <div className="alert alert-danger">Error cargando la orden.</div>;
  if (buyerError) return <div className="alert alert-danger">Error cargando la cuenta del comprador.</div>;

  // ✅ Ya podemos acceder a `order` y `buyerAccount`
  console.log("Orden recibida:", order);
  console.log("Cuenta del comprador:", buyerAccount);

  // 🔥 Construcción de la URL de la imagen
  const email = buyerAccount?.buyerEmail;
  const startImageUrl = `https://imagesfctt.blob.core.windows.net/imagenes/buyers/${email}/${orderId}-start.jpg`;
  const endImageUrl = `https://imagesfctt.blob.core.windows.net/imagenes/buyers/${email}/${orderId}-final.jpg`;
  

  return (
    <div className="container mt-5">
      <Card className="shadow-sm border-0">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <h3 className="m-0">
            <KTIcon iconName="document" className="me-2" />
            Order Info - {buyerAccount?.buyerEmail}
          </h3>
          <Link to="/" className="btn btn-light">
            Go back
          </Link>
        </Card.Header>

        <Card.Body>
          <table className="table table-hover table-rounded border-gray-300 gy-3 text-white">
            <thead>
              <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-300">
                <th className="text-center">Order ID</th>
                <th className="text-center">Screen Start</th>
                <th className="text-center">Screen End</th>
                <th className="text-center">Last Update</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">{orderId}</td>

                {/* Imagen inicial */}
                <td className="text-center">
                  {!startImageUrl || startImageError ? (
                    <span className="text-muted">No Image</span>
                  ) : (
                    <a href={startImageUrl} target="_blank" rel="noreferrer">
                      <img
                        src={startImageUrl}
                        alt="Start Screenshot"
                        className="img-fluid rounded"
                        onError={() => setStartImageError(true)}
                        style={{ maxWidth: "200px", border: "1px solid #ccc" }}
                      />
                    </a>
                  )}
                </td>

                {/* Imagen final */}
                <td className="text-center">
                  {!endImageUrl || endImageError ? (
                    <span className="text-muted">No Image</span>
                  ) : (
                    <a href={endImageUrl} target="_blank" rel="noreferrer">
                      <img
                        src={endImageUrl}
                        alt="Final Screenshot"
                        className="img-fluid rounded"
                        onError={() => setEndImageError(true)}
                        style={{ maxWidth: "200px", border: "1px solid #ccc" }}
                      />
                    </a>
                  )}
                </td>

                <td className="text-center">
                  {new Date(order.updatedAt).toLocaleString()}
                </td>
              </tr>
            </tbody>

          </table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default OrderInfo;
