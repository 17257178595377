/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../helpers'
import {useMutation, useQueryClient} from 'react-query'
import Swal from 'sweetalert2'
import { loginSupplierAccount } from '../../../../app/services/supplierServices/loginSupplierAccount'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateAppModalSupplier = ({show, handleClose}: Props) => {
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null)
  const queryClient = useQueryClient()

  // ✅ React Query Mutation para manejar login
  const loginMutation = useMutation(loginSupplierAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('supplierAccounts') // 🔄 Refresca la tabla tras login exitoso
    },
  })

  const validationSchema = Yup.object().shape({
    supplierEmail: Yup.string().email('Invalid email').required('Email is required'),
    supplierPassword: Yup.string().min(4, 'Minimum 4 characters').required('Password is required'),
    supplierBackupCode1: Yup.string()
      .length(8, 'Must be 8 characters'),
      // .required('Backup Code is required'),
    supplierOrder: Yup.number()
      .positive('Must be a positive number')
      // .integer('Must be an integer')
      .required('Order Amount is required'),
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2 className='text-warning'>Supplier Data</h2>
        <div className='btn btn-sm btn-icon btn-active-color-warning' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <Formik
          initialValues={{
            supplierEmail: '',
            supplierPassword: '',
            supplierBackupCode1: '',
            supplierGAuth: '',
            supplierAccountPlatform: '',
            supplierOrder: '',
            accountType: 'suppliers',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, {setSubmitting, validateForm}) => {
            const errors = await validateForm();
            if (Object.keys(errors).length > 0) {
              setSubmitting(false);
              console.warn('❌ Form has validation errors:', errors);
              return;
            }

            let realOrderAmount = Number(values.supplierOrder) * 1000

            if (realOrderAmount > 10000000) {
              const formattedAmount = realOrderAmount.toLocaleString()
              const confirmMessage = `Are you sure the order is for ${formattedAmount} coins?`

              if (!window.confirm(confirmMessage)) {
                setSubmitting(false)
                return
              }
            }

            // 🧼 Limpiar valores antes de enviar
            const cleanedValues = {
              ...values,
              supplierEmail: values.supplierEmail.trim(),
              supplierPassword: values.supplierPassword.trim(),
              supplierBackupCode1: values.supplierBackupCode1.trim(),
              supplierGAuth: values.supplierGAuth.trim(),
              supplierOrder: realOrderAmount,
              supplierAccountPlatform: selectedPlatform,
            }

            console.log('📌 Datos del formulario antes de enviar:', cleanedValues)
           
            queryClient.setQueryData('supplierAccounts', (oldData: any) => {
              if (!oldData) {
                return [
                  {
                    ...cleanedValues,
                    id: 'Loading...',
                    updatedAt: new Date().toISOString(),
                    supplierAccountStatus: 'LOGGING IN...',
                    supplierAccountPlatform: cleanedValues.supplierAccountPlatform,
                  },
                ]
              }
          
              return [
                ...oldData,
                {
                  ...cleanedValues,
                  id: 'Loading...',
                  updatedAt: new Date().toISOString(),
                  supplierAccountStatus: 'LOGGING IN...',
                },
              ]
            })

            // ✅ Mostrar mensaje de éxito y cerrar modal inmediatamente
            Swal.fire({
              icon: 'success',
              title: 'Order submitted',
              text: 'Your order has been added successfully.',
              showConfirmButton: false,
              timer: 1500, // Desaparece automáticamente
              background: '#1e1e1e',
              color: '#ffffff',
            })

            handleClose()
            loginMutation.mutate(cleanedValues)
            setSubmitting(false)
          }}
        >
          {({errors, touched}) => (
            <Form noValidate id='kt_modal_create_app_form'>
              {/* Email */}
              <div className='mb-4'>
                <div className='input-group'>
                  <Field
                    type='email'
                    name='supplierEmail'
                    placeholder='Email'
                    className={`form-control ${
                      errors.supplierEmail && touched.supplierEmail ? 'is-invalid' : ''
                    }`}
                  />
                </div>
                <ErrorMessage name='supplierEmail' component='div' className='text-danger' />
              </div>

              {/* Password */}
              <div className='mb-4'>
                <div className='input-group'>
                  {/* <KTIcon iconName='lock' className='fs-3' /> */}
                  <Field
                    type='text'
                    name='supplierPassword'
                    placeholder='Password'
                    className={`form-control ${
                      errors.supplierPassword && touched.supplierPassword ? 'is-invalid' : ''
                    }`}
                  />
                </div>
                <ErrorMessage name='supplierPassword' component='div' className='text-danger' />
              </div>

              {/* Backup Code */}
              <div className='mb-4'>
                <Field
                  type='text'
                  name='supplierBackupCode1'
                  placeholder='Backup Code'
                  className={`form-control ${
                    errors.supplierBackupCode1 && touched.supplierBackupCode1 ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='supplierBackupCode1' component='div' className='text-danger' />
              </div>

              {/* Google Auth */}
              <div className='mb-4'>
                <Field
                  type='text'
                  name='supplierGAuth'
                  placeholder='Google Auth'
                  className={`form-control ${
                    errors.supplierGAuth && touched.supplierGAuth ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='supplierGAuth' component='div' className='text-danger' />
              </div>

              {/* Order Amount */}
              <div className='mb-4'>
                <Field
                  type='number'
                  name='supplierOrder'
                  placeholder='Order Amount'
                  className={`form-control ${
                    errors.supplierOrder && touched.supplierOrder ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='supplierOrder' component='div' className='text-danger' />
              </div>

              {/* Platform Selection (PSN / PC) */}
              <div className='mb-4 d-flex justify-content-center'>
                <button
                  type='button'
                  className={`btn btn-sm me-3 ${
                    selectedPlatform === 'PSN' ? 'btn-primary' : 'btn-light-primary'
                  }`}
                  onClick={() => setSelectedPlatform('PSN')}
                >
                  <KTIcon iconName='play' className='fs-3' />
                  PSN
                </button>
                <button
                  type='button'
                  className={`btn btn-sm ${
                    selectedPlatform === 'PC' ? 'btn-warning' : 'btn-light-warning'
                  }`}
                  onClick={() => setSelectedPlatform('PC')}
                >
                  <KTIcon iconName='monitor' className='fs-3' />
                  PC
                </button>
              </div>

              {/* Add Account Button */}
              <div className='text-center'>
                <button type='submit' className='btn btn-lg btn-warning text-black'>
                  Add Supplier
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {CreateAppModalSupplier}
