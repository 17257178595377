/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTIcon} from '../../../helpers'
import {useNavigate} from 'react-router-dom'
import {useMutation, useQueryClient} from 'react-query'
import {loginBuyerAccount} from '../../../../app/services/buyerServices/loginBuyerAccount'
import Swal from 'sweetalert2'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateAppModal = ({show, handleClose}: Props) => {
  const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null)
  const queryClient = useQueryClient()

  // ✅ React Query Mutation para manejar login
  const loginMutation = useMutation(loginBuyerAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries('buyerAccounts') // 🔄 Refresca la tabla tras login exitoso
    },
  })

  const validationSchema = Yup.object().shape({
    buyerEmail: Yup.string().email('Invalid email').required('Email is required'),
    buyerPassword: Yup.string().min(4, 'Minimum 4 characters').required('Password is required'),
    buyerBackupCode: Yup.string().length(8, 'Must be 8 characters'),
    // .required('Backup Code is required'),
    buyerOrder: Yup.number()
      .positive('Must be a positive number')
      // .integer('Must be an integer')
      .required('Order Amount is required'),
  })

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Order Data</h2>
        <div className='btn btn-sm btn-icon btn-active-color-info' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        <Formik
          initialValues={{
            buyerEmail: '',
            buyerPassword: '',
            buyerBackupCode: '',
            buyerGAuth: '',
            buyerAccountPlatform: '',
            buyerOrder: '',
            accountType: 'buyers',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, {setSubmitting, validateForm}) => {
            const errors = await validateForm();
            if (Object.keys(errors).length > 0) {
              setSubmitting(false);
              console.warn('❌ Form has validation errors:', errors);
              return;
            }

            let realOrderAmount = Number(values.buyerOrder) * 1000

            if (realOrderAmount > 10000000) {
              const formattedAmount = realOrderAmount.toLocaleString();
              const confirmMessage = `Are you sure the order is for ${formattedAmount} coins?`;

              if (!window.confirm(confirmMessage)) {
                setSubmitting(false);
                return;
              }
            }
            if (realOrderAmount < 50000) {
              const formattedAmount = realOrderAmount.toLocaleString();
              const confirmMessage = `Are you sure the order is for ${formattedAmount} coins?`;

              if (!window.confirm(confirmMessage)) {
                setSubmitting(false);
                return;
              }
            }
            const cleanedValues = {
              ...values,
              buyerEmail: values.buyerEmail.trim(),
              buyerPassword: values.buyerPassword.trim(),
              buyerBackupCode: values.buyerBackupCode.trim(),
              buyerGAuth: values.buyerGAuth.trim(),
              buyerOrder: realOrderAmount,
              buyerAccountPlatform: selectedPlatform,
            };
            console.log('📌 Datos del formulario antes de enviar:', cleanedValues);

            // 🔥 **Agregar el Buyer manualmente al cache antes de enviarlo al backend**
            queryClient.setQueryData('buyerAccounts', (oldData: any) => {
              const newItem = {
                id: 'Loading...',
                buyerEmail: cleanedValues.buyerEmail,
                buyerAccountStatus: 'LOGGING IN...',
                buyerAccountPlatform: cleanedValues.buyerAccountPlatform,
              };
              return oldData ? [...oldData, newItem] : [newItem];
            });
            

            // ✅ Mostrar mensaje de éxito y cerrar modal inmediatamente
            Swal.fire({
              icon: 'success',
              title: 'Order submitted',
              text: 'Your order has been added successfully.',
              showConfirmButton: false,
              timer: 1500, // Desaparece automáticamente
              background: '#1e1e1e',
              color: '#ffffff',
            })

            handleClose();
            loginMutation.mutate(cleanedValues);
            setSubmitting(false);
          }}
        >
          {({errors, touched, isValid, dirty}) => (
            <Form noValidate id='kt_modal_create_app_form'>
              {/* Email */}
              <div className='mb-4'>
                <div className='input-group'>
                  <Field
                    type='email'
                    name='buyerEmail'
                    placeholder='Email'
                    className={`form-control ${
                      errors.buyerEmail && touched.buyerEmail ? 'is-invalid' : ''
                    }`}
                  />
                </div>
                <ErrorMessage name='buyerEmail' component='div' className='text-danger' />
              </div>

              {/* Password */}
              <div className='mb-4'>
                <div className='input-group'>
                  {/* <KTIcon iconName='lock' className='fs-3' /> */}
                  <Field
                    type='text'
                    name='buyerPassword'
                    placeholder='Password'
                    className={`form-control ${
                      errors.buyerPassword && touched.buyerPassword ? 'is-invalid' : ''
                    }`}
                  />
                </div>
                <ErrorMessage name='buyerPassword' component='div' className='text-danger' />
              </div>

              {/* Backup Code */}
              <div className='mb-4'>
                <Field
                  type='text'
                  name='buyerBackupCode'
                  placeholder='Backup Code'
                  className={`form-control ${
                    errors.buyerBackupCode && touched.buyerBackupCode ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='buyerBackupCode' component='div' className='text-danger' />
              </div>

              {/* Google Auth */}
              <div className='mb-4'>
                <Field
                  type='text'
                  name='buyerGAuth'
                  placeholder='Google Auth'
                  className={`form-control ${
                    errors.buyerGAuth && touched.buyerGAuth ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='buyerGAuth' component='div' className='text-danger' />
              </div>

              {/* Order Amount */}
              <div className='mb-4'>
                <Field
                  type='number'
                  name='buyerOrder'
                  placeholder='Order Amount'
                  className={`form-control ${
                    errors.buyerOrder && touched.buyerOrder ? 'is-invalid' : ''
                  }`}
                />
                <ErrorMessage name='buyerOrder' component='div' className='text-danger' />
              </div>

              {/* Platform Selection (PSN / PC) */}
              <div className='mb-4 d-flex justify-content-center'>
                <button
                  type='button'
                  className={`btn btn-sm me-3 ${
                    selectedPlatform === 'PSN' ? 'btn-primary' : 'btn-light-primary'
                  }`}
                  onClick={() => setSelectedPlatform('PSN')}
                >
                  <KTIcon iconName='play' className='fs-3' />
                  PSN
                </button>
                <button
                  type='button'
                  className={`btn btn-sm ${
                    selectedPlatform === 'PC' ? 'btn-warning' : 'btn-light-warning'
                  }`}
                  onClick={() => setSelectedPlatform('PC')}
                >
                  <KTIcon iconName='monitor' className='fs-3' />
                  PC
                </button>
              </div>

              {/* Add Account Button */}
              <div className='text-center'>
                <button type='submit' className='btn btn-lg btn-primary'>
                  Add Order
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {CreateAppModal}
